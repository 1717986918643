<template>
  <div>
    <!-- PM物质曲线图 -->
    <Echart
        :options="options"
        id="bottomChartPM"
        height="240px"
        width="100%"
    ></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart'

export default {
  data() {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({})
    },
  },
  watch: {
    cdata: {
      handler(newData) {
        this.options = {
          tooltip: {
            trigger: "axis",
            backgroundColor: "rgba(255,255,255,0.1)",
            axisPointer: {
              type: "cross",
              label: {
                show: true,
                backgroundColor: "#7B7DDC"
              }
            }
          },
          legend: {
            data: ["PM1.0", "PM2.5", "PM10"],
            textStyle: {
              color: "#B4B4B4",
              fontSize: 18,
              fontWeight: 600
            },
            top: '-1',
            right: "40"
          },
          grid: {
            x: "8%",
            width: "88%",
            y: "4%"
          },
          xAxis: {
            data: newData.category,
            axisLine: {
              lineStyle: {
                color: "#B4B4B4"
              }
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: '#B4B4B4',
              interval: 0,
              fontSize: 14
            },
          },
          yAxis: [
            {
              splitLine: {show: false},
              axisLine: {
                lineStyle: {
                  color: "#B4B4B4"
                }
              },

              axisLabel: {
                formatter: "{value} "
              }
            },
            {
              splitLine: {show: false},
              axisLine: {
                lineStyle: {
                  color: "#B4B4B4"
                }
              },
              axisLabel: {
                formatter: "{value} "
              }
            }
          ],
          series: [

            {
              name: "PM1.0",
              type: "line",
              smooth: true,
              showAllSymbol: true,
              symbol: "emptyCircle",
              symbolSize: 8,
              // yAxisIndex: 1,
              itemStyle: {
                normal: {
                  color: "#FC97AF"
                }
              },
              data: newData.PM1Data
            },
            {
              name: "PM2.5",
              type: "line",
              barGap: "-100%",
              barWidth: 10,
              itemStyle: {
                normal: {
                  color: "#2EC7C9"
                }
              },
              data: newData.PM25Data
            },
            {
              name: "PM10",
              type: "line",
              barGap: "-100%",
              barWidth: 10,
              itemStyle: {
                normal: {
                  color: "#C12E34"
                }
              },
              data: newData.PM10Data
            },
          ]
        }
      },
      immediate: true,
      deep: true
    },
  },
}
</script>
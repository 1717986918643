<template>
  <div id="index" ref="appRef">
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div class="d-flex jc-center">
          <dv-decoration-10 class="dv-dec-10"/>
          <div class="d-flex jc-center">
            <dv-decoration-8 class="dv-dec-8" :color="decorationColor"/>
            <div class="title">
              <span class="title-text" style="font-weight: 600;font-size: 40px;word-break: keep-all;bottom:15px">多传感器信息融合数据可视化平台</span>
              <dv-decoration-6 class="dv-dec-6" :reverse="true" :color="['#50e3c2', '#67a1e5']"/>
            </div>
            <dv-decoration-8 class="dv-dec-8" :reverse="true" :color="decorationColor"/>
          </div>
          <dv-decoration-10 class="dv-dec-10-s"/>
        </div>

        <!-- 第二行 -->
        <div class="d-flex jc-between px-2" style="justify-content: right;padding-right: 0px">
<!--          <div class="d-flex aside-width">
            <div class="react-left ml-4 react-l-s">
              <span class="react-left"></span>
              <span class="text">数据分析1</span>
            </div>
            <div class="react-left ml-3">
              <span class="text">数据分析2</span>
            </div>
          </div>-->
<!--          <div class="d-flex aside-width">
            <div class="react-right bg-color-blue mr-3">
              <span class="text fw-b"></span>
            </div>
            <div class="react-right mr-4 react-l-s">
              <span class="react-after"></span>-->
              <span class="text" style="font-size: 25px;font-weight: 600">{{ dateYear }} {{ dateWeek }} {{ dateDay }}</span>
<!--            </div>
          </div>-->
        </div>

        <div class="body-box">
          <!-- 第三行数据 -->
          <span style="text-align: center;font-size: 30px;font-weight: 600;margin: 10px">环境空气质量监测</span>
          <div class="content-box" style="display: flex;justify-content: space-between">

            <div>
                <div style="text-align: center">
                  <span class="text"></span>
                </div>
              <dashboardChartTem/>
            </div>
            <div>
              <div style="text-align: center">
                <span class="text"></span>
              </div>
              <dashboardChartHum/>
            </div>
            <div>
              <div style="text-align: center">
                <span class="text"></span>
              </div>
              <img style="height: 300px" src="../assets/banner.png">
            </div>
            <div>
              <div style="text-align: center">
                <span class="text"></span>
              </div>
              <dashboardChartIllu/>

            </div>
            <div>
              <div style="text-align: center">
                <span class="text"></span>
              </div>
              <dashboardChartAlc/>
            </div>
          </div>
          <!-- 第四行数据 -->
          <div class="content-box" style="display: flex;justify-content: space-between">
            <div>
                <div style="text-align: center">
                  <span class="text"></span>
                </div>
              <dashboardChartFor/>
            </div>

            <div>
              <div style="text-align: center">
                <span class="text"></span>
              </div>
              <dashboardChartTvoc/>
            </div>

            <div style="width: 300px">
              <div style="text-align: center">
                <span class="text"></span>
              </div>
              <dashboardChartPM1/>
            </div>

            <div>
              <div style="text-align: center">
                <span class="text"></span>
              </div>
              <dashboardChartPM25/>
            </div>

            <div>
              <div style="text-align: center">
                <span class="text"></span>
              </div>
              <dashboardChartPM10/>
            </div>
          </div>

          <!-- 第五行数据 -->
          <div class="bottom-box" style="margin-top: 0">
            <dv-border-box-12>
              <bottomChartTemAndHum/>
            </dv-border-box-12>
            <dv-border-box-12>
              <bottomChartPM/>
            </dv-border-box-12>
          </div>
        </div>

        <div class="d-flex jc-center">
          <a href="http://beian.miit.gov.cn" target="_blank"><span class="title-text">沪ICP备2021030167号-1</span></a>
        </div>

      </div>

    </div>


  </div>

</template>

<script>
import drawMixin from "../utils/drawMixin";
import {formatTime} from '../utils/index.js';
import dashboardChartTem from './dashboardChartTem';
import dashboardChartHum from './dashboardChartHum';
import dashboardChartIllu from './dashboardChartIllu';
import dashboardChartAlc from './dashboardChartAlc';
import dashboardChartFor from './dashboardChartFor';
import dashboardChartTvoc from './dashboardChartTvoc';
import dashboardChartPM1 from './dashboardChartPM1';
import dashboardChartPM25 from './dashboardChartPM25';
import dashboardChartPM10 from './dashboardChartPM10';
import bottomChartTemAndHum from './bottomChartTemAndHum';
import bottomChartPM from './bottomChartPM';

export default {
  mixins: [drawMixin],
  data() {
    return {
      timing: null,
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
      decorationColor: ['#568aea', '#000000']
    }
  },
  components: {
    dashboardChartTem,
    dashboardChartHum,
    dashboardChartIllu,
    dashboardChartAlc,
    dashboardChartFor,
    dashboardChartTvoc,
    dashboardChartPM1,
    dashboardChartPM25,
    dashboardChartPM10,
    bottomChartTemAndHum,
    bottomChartPM
  },
  mounted() {
    this.timeFn();
    this.cancelLoading();
  },
  beforeDestroy() {
    clearInterval(this.timing)
  },
  methods: {
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), 'HH: mm: ss')
        this.dateYear = formatTime(new Date(), 'yyyy-MM-dd')
        this.dateWeek = this.weekday[new Date().getDay()]
      }, 1000)
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false
      }, 500)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/index.scss';
</style>

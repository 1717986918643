<template>
  <div>
    <Chart :cdata="cdata"/>
  </div>
</template>

<script>
import Chart from './chart.vue'

export default {
  data() {
    return {
      cdata: {
        category: [
          "时间点1",
          "时间点2",
          "时间点3",
          "时间点4",
          "时间点5",
          "时间点6",
          "时间点7",
          "时间点8",
          "时间点9",
          "时间点10"
        ],
        PM1Data: [],
        PM25Data: [],
        PM10Data: []
      }
    };
  },
  components: {
    Chart,
  },
  mounted() {
    this.queryTopTenPM1Data();
    setInterval(() => this.queryTopTenPM1Data(), 45000);

    this.queryTopTenPM25Data();
    setInterval(() => this.queryTopTenPM25Data(), 45000);

    this.queryTopTenPM10Data();
    setInterval(() => this.queryTopTenPM10Data(), 45000)


  },
  methods: {
    setData(funcationName, funcationValue) {
      if (funcationName == "PM1Data") {
        this.cdata.PM1Data = funcationValue;
      } else if (funcationName == "PM25Data") {
        this.cdata.PM25Data = funcationValue;
      } else if (funcationName == "PM10Data") {
        this.cdata.PM10Data = funcationValue;
      }
    },
    //查询最新的10条PM1.0数据
    queryTopTenPM1Data() {
      var that = this;
      this.$http({
        method: 'get',
        url: '/sensor_data/query_top_ten_Data',
        params: {
          productKey: "a1laq3cw7Lh",
          deviceName: "NodeMcu05",
          functionName: "pm1",
        }
      }).then(function (response) {
        that.setData("PM1Data", response.data.data);
      })
    },
    //查询最新的10条PM2.5数据
    queryTopTenPM25Data() {
      var that = this;
      this.$http({
        method: 'get',
        url: '/sensor_data/query_top_ten_Data',
        params: {
          productKey: "a1laq3cw7Lh",
          deviceName: "NodeMcu05",
          functionName: "pm25",
        }
      }).then(function (response) {
        that.setData("PM25Data", response.data.data);
      })
    },
    //查询最新的10条PM10数据
    queryTopTenPM10Data() {
      var that = this;
      this.$http({
        method: 'get',
        url: '/sensor_data/query_top_ten_Data',
        params: {
          productKey: "a1laq3cw7Lh",
          deviceName: "NodeMcu05",
          functionName: "pm10",
        }
      }).then(function (response) {
        that.setData("PM10Data", response.data.data);
      })
    }

  }
};
</script>

<style lang="scss" scoped>
</style>
<template>
  <div>
    <!-- 甲醛 -->
    <Echart
      :options="options"
      id="dashboardChartFor"
      height="300px"
      width="300px"
    ></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart'
export default {
  data () {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({})
    },
  },
  watch: {
    cdata: {
      handler (newData) {
        this.options = {
          title: {
            show: true,
            text: '甲醛浓度',
            textStyle: {
              fontSize: 30,
              color: "#eedd78",
              lineHeight: 25
            },
            top: 0,
            left: 85
          },
          series: [
            {
              type: 'gauge',
              min:0,
              max: 10,
              splitNumber: 10,
              data: newData.data,
              detail: {
                formatter: '浓度\n{value}PPM',
                lineHeight: 30,
                fontSize: 18,
                fontWeight: 600,
                color: '#ffb248'
              },
              axisLine: {
                show: true,

                lineStyle: {
                  shadowBlur: 1.5,
                  color: [
                    [0.3, '#67e0e3'], // 0~30%
                    [0.7, '#32c5e9'], // 30~70%
                    [1, '#37a2da'] // 70~100%
                    // ...
                  ],
                  width: 18
                }
              },
              progress: {
                show: true,
                itemStyle: {
                  color: "#d20e33"
                }
              },
              animationCurve: 'easeOutBounce'
            }
          ]
        }
      },
      immediate: true,
      deep: true
    }
  }
};
</script>
<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue';
export default {
  data () {
    return {
      cdata: {
        data: [
          { value: 0}
        ]
      }
    }
  },
  components: {
    Chart,
  },
  methods: {
    setData (response)
    {
      this.cdata.data[0].value = response.data.functionValue;
    },

    sendRequest(){
      var that = this;
      this.$http({
        method: 'get',
        url: '/sensor_data/query_latest_data',
        params: {
          productKey: "a1laq3cw7Lh",
          deviceName: "NodeMcu05",
          functionName: "hchoppm",
        }
      }).then(function (response) {
        that.setData(response.data);
      })
    }
  },
  mounted() {
    this.sendRequest();
    setInterval(() => this.sendRequest(),45000)
  }
}
</script>
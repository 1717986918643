<template>
  <div id="bottomLeft">
    <div class="bg-color-black">
      <div class="d-flex pt-21 pl-21" style="justify-content: center">
<!--        <span>-->
<!--          <icon name="chart-bar" class="text-icon"></icon>-->
<!--        </span>-->
        <div class="d-flex">
          <span class="fs-xl text mx-2" style="font-size: 30px;margin-bottom: 15px;color: #eedd78;font-weight: 550">空气质量曲线</span>
        </div>
      </div>
      <div>
        <BottomChartPM />
      </div>
    </div>
  </div>
</template>

<script>
import BottomChartPM from '@/components/echart/bottom/bottomChartPM'
export default {
  components: {
    BottomChartPM
  }
}
</script>

<style lang="scss" scoped>
$box-height: 280px;
$box-width: 100%;
#bottomLeft {
  padding: 20px 16px;
  height: $box-height;
  width: $box-width;
  border-radius: 5px;
  .bg-color-black {
    height: $box-height - 35px;
    border-radius: 10px;
  }
  .text {
    color: #c3cbde;
  }
  .chart-box {
    margin-top: 16px;
    width: 170px;
    height: 170px;
    .active-ring-name {
      padding-top: 10px;
    }
  }
}
</style>

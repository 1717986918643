<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue'
export default {
  data () {
    return {
      cdata: {
        category: [
          "时间点1",
          "时间点2",
          "时间点3",
          "时间点4",
          "时间点5",
          "时间点6",
          "时间点7",
          "时间点8",
          "时间点9",
          "时间点10"
        ],
        temData: [],
        humData: []
      }
    };
  },
  components: {
    Chart,
  },
  mounted () {
    this.queryTopTenTemData();
    setInterval(() => this.queryTopTenTemData(),45000);

    this.queryTopTenHumData();
    setInterval(() => this.queryTopTenHumData(),45000)


  },
  methods: {
    setData (funcationName,funcationValue) {
      if (funcationName == "temData") {
        this.cdata.temData = funcationValue;
      } else if (funcationName == "humData") {
        this.cdata.humData = funcationValue;
      }
    },
    //查询最新的10条温度数据
    queryTopTenTemData(){
      var that = this;
      this.$http({
        method: 'get',
        url: '/sensor_data/query_top_ten_Data',
        params: {
          productKey: "a1laq3cw7Lh",
          deviceName: "NodeMcu02",
          functionName: "temp",
        }
      }).then(function (response) {
        that.setData("temData",response.data.data);
      })
    },
    //查询最新的10条湿度数据
    queryTopTenHumData(){
      var that = this;
      this.$http({
        method: 'get',
        url: '/sensor_data/query_top_ten_Data',
        params: {
          productKey: "a1laq3cw7Lh",
          deviceName: "NodeMcu02",
          functionName: "humi",
        }
      }).then(function (response) {
        that.setData("humData",response.data.data);
      })
    }

  }
};
</script>

<style lang="scss" scoped>
</style>
<template>
  <div id="bottomRight">
    <div class="bg-color-black">
<!--      <div class="d-flex pt-2 pl-2">
        <span>
          <icon name="chart-area" class="text-icon"></icon>
        </span>
        <div class="d-flex">
          <div class="decoration2">
            <dv-decoration-2 :reverse="true" style="width:5px;height:6rem;" />
          </div>
        </div>
      </div>-->
      <div>
        <DashboardChartPM10/>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardChartPM10 from "@/components/echart/dashboard/dashboardChartPM10";
export default {
  components: {
    DashboardChartPM10
  }
};
</script>

<style lang="scss" class>
$box-height: 320px;
$box-width: 100%;
#bottomRight {
  padding: 14px 16px;
  height: $box-height;
  width: $box-width;
  border-radius: 5px;
  .bg-color-black {
    height: $box-height - 30px;
    border-radius: 10px;
  }
  .text {
    color: #c3cbde;
  }
  //下滑线动态
  .decoration2 {
    position: absolute;
    right: 0.125rem;
  }
  .chart-box {
    margin-top: 16px;
    width: 170px;
    height: 170px;
    .active-ring-name {
      padding-top: 10px;
    }
  }
}
</style>